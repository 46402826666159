var scripts = [
    'https://cdn.jsdelivr.net/npm/core-js-bundle/minified.js',
    'https://cdn.jsdelivr.net/npm/regenerator-runtime/runtime.js'
];


!function( d ) {
        if( !d.currentScript ){
            for (var i = 0; i < scripts.length; i++) {
                var s = d.createElement('script');
                s.src = scripts[i];
                d.head.appendChild(s);
            }
        }
    }(document);